<template>
  <div class="box">
    <GlobalChunk icon="edit"
                 title="编辑商品模板">
      <template v-slot:filter>
        <span style="cursor: pointer" @click="()=>$router.push('/oldMachine/templateList')">{{
            info.typeName
          }}分类</span> >> {{ info.isIos }}品牌
      </template>
      <template v-slot>
        <div class="setting-inner" v-for="(item,index) in lists" :key="item.name">
          <div class="title">
            <h2>{{ item.name }}</h2>
            <p>{{ item.content }}</p>
          </div>
          <FormSetting @updateForm="updateForm" :is-switch="item.isSwitch" :row="item.rowData" :currentIndex="index"/>
        </div>
      </template>
    </GlobalChunk>
    <div style="margin-left: 20px;margin-top: 20px">
      <el-button @click="save" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script>
import GlobalChunk from "@/components/global/components/GlobalChunk.vue";
import FormSetting from "@/views/oldMachine/components/FormSetting.vue";
import _api from "@/utils/request";

export default {
  name: "TemplateList",
  components: {FormSetting, GlobalChunk},
  watch: {
    // 状态嵌套多层，响应式失效，单独声明状态操作
    listData: {
      handler() {
        this.lists = this.listData;
      },
      // 深层监听
      deep: true
    }
  },
  data() {
    const listData = [
      {
        name: "旧机信息",
        content: "",
        // 是否能切换
        isSwitch: true,
        // 列
        rowData: [],
      },
      {
        name: "成色情况",
        content: "说明：默认第一个选项为正常项，其余选项不做标记",
        // 是否能切换
        isSwitch: false,
        rowData: [],
      },
      {
        name: "功能情况",
        content: "说明：默认第一个选项为正常项，其余选项不做标记",
        // 是否能切换
        isSwitch: false,
        rowData: [],
      }
    ]
    return {
      // 初始请求数据
      listData,
      // 模板id
      id: "",
      isRq: false,
      rowDataArr: [],
      // 操作数据项
      lists: [],
      info: {
        typeName: '',
        isIos: ''
      }
    }
  },
  // 设置模板详情
  created() {
    const {id, typeName, isIos} = this.$route.query;
    this.info = {
      typeName,
      isIos
    }
    this.id = id;
    this.getData();
  },
  mounted() {
  },
  methods: {
    // 最开始下拉类型字段取为type，接口为layout，懒得改了转换下
    getType(v) {
      v = v ?? [];
      return v.map(item => {
        item.values = item.values ?? [];
        return {
          ['values']: item.values.map(v => {
            return {
              value: v
            }
          }),
          ['type']: item.layout ?? '',
          ['label']: item.label ?? ''
        }
      })
    },
    getData() {
      _api.productTemplateDetail(this.id).then(r => {
        const data = r.data ?? {bases: [], fineness: [], func: []};
        const {bases, fineness, func} = data;
        // 避免首次进来被子组件监听
        this.isRq = true;
        this.listData = this.listData.map((v, i) => {
              if (i === 0) {
                v.rowData = this.getType(bases)
              }
              if (i === 1) {
                v.rowData = this.getType(fineness)
              }
              if (i === 2) {
                v.rowData = this.getType(func)
              }
              return v;
            }
        );
      });
    },
    // 更新索引更新form
    updateForm(v, i) {
      if (!this.isRq) {
        return;
      }
      this.lists = this.lists.map((item, index) => {
        if (index === i) {
          item.rowData = v;
        }
        return item
      });
    },
    // 设置保存参数
    setSaveParam(i) {
      const rowData = this.listData[i].rowData;
      const param = rowData.map(item => {
        const v = {
          label: item.label,
          layout: item.type,
        };
        if (item.type === '01') {
          v.values = item.values.map(v => {
            return v.value
          })
        }
        return v
      })
      return param;
    },
    save() {
      const bases = this.setSaveParam(0);
      const fineness = this.setSaveParam(1)
      const func = this.setSaveParam(2);
      const params = {
        templateNo: this.id,
        bases,
        fineness,
        func
      };
      _api.saveProductTemplate(params).then(r => {
        this.$message.success("保存成功")
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;

  /deep/ .global-chunk_header_filter {
    padding-left: 20px;
    padding-top: 0;
    color: #0981FF;
  }

  /deep/ .global-chunk_header {
    border: 0;
  }
}

.setting-inner {
  width: 100%;
  margin-bottom: 20px;

  > .title {
    width: 100%;
    display: flex;
    align-items: center;

    > h2 {
      font-size: 14px;
      color: #000;
      font-weight: 900;
      padding-left: 6px;
      position: relative;
      line-height: 14px;
      margin-right: 30px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 14px;
        border-radius: 3px;
        background: #0981FF;
      }
    }

    > p {
      font-size: 12px;
      line-height: 12px;
      color: rgb(249, 31, 31);
    }
  }
}
</style>

