<template>
  <div class="box">
    <div v-for="(item,index) in rowData" :key="index">
      <!-- 选择项 -->
      <div>
        <div class="mr20">
          <el-input size="mini" v-model="item.label" placeholder="请输入参数项名称，最多10个字"
                    maxlength="10"></el-input>
        </div>
        <div class="mr20">
          <el-select size="mini" v-model="item.type" placeholder="请选择" :disabled="!isSwitch">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 此时的index是整个行的索引  -->
        <div class="text_line mr20" style="color: rgb(249, 31, 31);" @click="delClick(index)">删除</div>
        <div class="text_line mr20" style="color: #0981FF" v-show="index !== rowData.length-1"
             @click="sortClick('bottom',index)">向下
        </div>
        <div class="text_line mr20" style="color: #0981FF" v-show="index !== 0" @click="sortClick('top',index)">
          向上
        </div>
      </div>
      <!-- 单选项 -->
      <div v-show="item.type === '01'">
        <!-- 除旧机信息外的第一个单选框tab边框为绿色、文字为绿色 -->
        <div :class="[currentIndex && i===0?'normal_style':'','add_btn']" v-for="(item,i) in item.values" :key="i">
          <!-- 此时的index是整个行的索引,i是tab渲染的索引  -->
          <div class="close" @click="delClick(i,index)"></div>
          <el-input
              :placeholder="currentIndex && i===0?'请输入正常描述，最多8个字':currentIndex === 0?'请输入旧机信息描述，最多8个字':'请输入异常描述，最多8个字'"
              size="mini"
              maxlength="8"
              v-model="item.value"/>
        </div>
        <div>
          <!-- 此时的index是整个行的索引  -->
          <el-button icon="el-icon-plus" size="mini" @click="addClick(index)">添加选项</el-button>
        </div>
      </div>
    </div>
    <el-button size="mini" style="width: 30%;margin-top: 20px;" @click="addClick()">添加参数项</el-button>
  </div>
</template>
<script>
export default {
  name: "FormSetting",
  props: {
    isSwitch: {
      type: Boolean,
      default: true
    },
    row: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: Number,
    }
  },
  created() {
    this.selectValue = this.isSwitch ? '00' : '01';
    this.rowData = this.row;
  },

  watch: {
    // 深层监听数据变化（数组对象属性失去了响应式）
    rowData: {
      handler() {
        // 更新父组件的form状态
        this.$emit("updateForm", this.rowData, this.currentIndex)
      },
      deep: true
    }
  },
  data() {
    return {
      rowData: [],
      // 下拉
      options: [
        {
          value: '00',
          label: '输入框'
        },
        {
          value: '01',
          label: '单选框'
        }
      ],
      // 输入值
      value: "",
      // 添加类型
      selectValue: "00",
    }
  },
  methods: {
    // 删除数组某数据
    delArr(arr, index) {
      arr.splice(index, 1);
    },
    // 删除行或者tab,i当前操作的index，index为具体的tab删除，他所输出的行索引，存在则是tab项删除，反之行删除
    delClick(i, index) {
      if (!index && index !== 0) {
        // 删除行
        return this.delArr(this.rowData, i)
      } else {
        // 删除tab
        return this.delArr(this.rowData[index].values, i);
      }
    },
    // 如果存在index参数，那么当前操作的是tab添加，反之为行添加
    addClick(i) {
      // 判断是行添加还是tab添加
      if (!i && i !== 0) {
        // 判断是否为旧机信息，默认选择输入框
        // 反之，成色和旧机，默认选择单选框
        const rowItem = {
          // 输入值
          label: "",
          // 设置类型
          type: "00",
          values: []
        };
        if (this.currentIndex === 0) {
          return this.rowData.push(rowItem);
        } else {
          rowItem.type = "01"
          // 添加行
          return this.rowData.push(rowItem)
        }
      } else {
        // tab添加
        this.rowData = this.rowData.map((v, index) => {
          if (i === index) {
            v.values.push({value: ''})
          }
          return v
        })
      }
    },
    // type判断是向上（top）还是向下（bottom）
    sortClick(type, index) {
      // 每次都要操作原本的值去更改，因此需要深拷贝数据，再将操作完成的数据填充
      const rowData = JSON.parse(JSON.stringify(this.rowData));
      rowData.forEach((v, i) => {
        if (index === i) {
          if (type === 'top') {
            rowData[i] = rowData[i - 1]
            rowData[i - 1] = v;
          } else {
            rowData[i] = rowData[i + 1];
            rowData[i + 1] = v;
          }
        }
      });
      this.rowData = rowData;
    }
  }
}
</script>
<style scoped lang="scss">
.box {
  .mr20 {
    margin-right: 20px;
  }

  > div > div:first-of-type {
    margin-top: 20px;
    display: flex;
    align-items: center;

    > div:first-of-type {
      width: 30%;
      max-width: 30%;
      min-width: 30%;
    }
  }

  > div > div:last-of-type {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
      margin-top: 10px;

      .el-button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .add_btn {
    width: 220px;
    max-width: 220px;
    min-width: 220px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #666666;
    margin-right: 10px;
    position: relative;
    overflow: hidden;

    > .close {
      cursor: pointer;
      content: "";
      position: absolute;
      right: 0px;
      top: 0px;
      width: 15px;
      height: 15px;
      z-index: 1;
      background: url("../../../../src/assets/images/close.png") right top / 80% 80% no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background: conic-gradient(from 90deg at 50% 0, rgb(112, 112, 112) 0, rgb(112, 112, 112) 45deg, transparent 45.1deg);
    }
  }
}

.normal_style {
  /deep/ .el-input__inner {
    border-color: rgb(133, 194, 110);
    color: rgb(133, 194, 110);

    &::placeholder {
      color: rgb(133, 194, 110);
    }
  }
}

.text_line {
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}
</style>